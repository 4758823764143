const searchbar = document.querySelector('.menu__top__searchbar');

const opensearchbarModalIcon = document.querySelector('.menu__top__searchbar__mobile__icon');
const closeSearchbarModalIcon = document.querySelector('.close_searchbar_mobile_modal');

function toggleSearchbar() {
    console.log(searchbar)
    searchbar.classList.toggle('active');
}

opensearchbarModalIcon && opensearchbarModalIcon.addEventListener('click', () => toggleSearchbar())
closeSearchbarModalIcon && closeSearchbarModalIcon.addEventListener('click', () => toggleSearchbar())


// Hamburger
const menuBottomContainer = document.querySelector('#menu__bottom__container')
if (menuBottomContainer) {
    const ham = document.querySelector('.ham')
    ham.addEventListener('click', () => {
        ham.classList.toggle('active')
        menuBottomContainer.classList.toggle('active')
    })
}

// // Menu position on scroll
// const menu = document.querySelector('#mdigimenu')
// // const menuMobile = document.querySelector('#menu-icon')
// const firstBurger = document.querySelector('.burger__container')

// function handleSticky() {
//     // let offset = menu.offsetHeight;
//     let offset = menu.offsetHeight;

//     if (window.scrollY > offset - 10) {
//         menu.classList.add("stickit");
//         firstBurger.style.cssText = `margin-top: ${offset}px !important`
//     } else if (window.scrollY < offset - 20) {
//         menu.classList.remove("stickit");
//         firstBurger.style.marginTop = 0
//     }
// }
// window.addEventListener("scroll", () => handleSticky());

