import Splide from '@splidejs/splide';

// Product Page Slider
const mainSlidersDiv = document.querySelector('#main-slider');
const thumbnailSliderDiv = document.querySelector('#thumbnail-slider');

if (mainSlidersDiv && thumbnailSliderDiv) {
    // Product slider Main Image
    var mainSlider = new Splide(mainSlidersDiv, {
        rewind: true,
        pagination: false,
        arrows: false
    });

    // Product slider thumbnails
    const thumbnailSlider = new Splide((thumbnailSliderDiv), {
        fixedWidth: 100,
        gap: "10px",
        rewind: true,
        pagination: false,
        arrows: false,
        isNavigation: true,
        breakpoints: {
            768: {
                fixedWidth: 60,
            },
        }
    });

    mainSlider.sync(thumbnailSlider);
    mainSlider.mount();
    thumbnailSlider.mount();
}

// Bouilder Ingredient : Slider
const sliders = [...document.querySelectorAll('.burger .simple-slider')];
sliders && sliders.forEach(slider => {
    const cover = slider.dataset.noCover == '1' ? false : true
    const height = slider.dataset.noCover == '1' ? 'unset' : slider.dataset.height + 'px'
    const heightMobile = slider.dataset.noCover == '1' ? 'unset' : slider.dataset.heightMobile + 'px'

    new Splide(slider, {
        type: 'loop',
        height: height,
        // width: "100vw",
        cover: cover,
        breakpoints: {
            768: {
                height: heightMobile,
                arrows: false
            },
        }
    }).mount();
});

// Bouilder Ingredient : Products slider
const productsSlider = document.querySelector('#products-slider');
if (productsSlider) {
    new Splide(productsSlider, {
        autoWidth: true,
        pagination: false,
        gap: "20px",
        breakpoints: {
            768: {
                arrows: false
            },
        }
    }).mount();
}

// Bouilder Ingredient : Products slider 2
const productsSlider2 = document.querySelector('#products-slider2');
if (productsSlider2) {
    new Splide(productsSlider2, {
        autoWidth: true,
        pagination: false,
        gap: "20px",
        breakpoints: {
            768: {
                arrows: false
            },
        }
    }).mount();
}


// Add an arrow after the products sliders
const productsSlidersLinks = [...document.querySelectorAll('.all-product-link')]
productsSlidersLinks && productsSlidersLinks.forEach(link => {
    const arrow = document.createElement('span')
    arrow.innerHTML = '>'
    link.appendChild(arrow)
})


// Bouilder Ingredient : Category slider (mobile)
const categorySliders = [...document.querySelectorAll('.category-slider')];
categorySliders && categorySliders.forEach(slider => {
    const height = slider.dataset.height + 'px'
    const heightMobile = slider.dataset.heightMobile + 'px'

    new Splide(slider, {
        // type: 'loop',
        height: height,
        autoWidth: true,
        gap: "20px",
        pagination: false,
        breakpoints: {
            768: {
                height: heightMobile,
            },
        },
    }).mount();
})
